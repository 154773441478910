import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Footer, Header } from '../components/bnbmatrix-ui';
import { Context } from '../Store';
import contractABI from '../abi/matrixprotocol.json';
import tokenABI from '../abi/matrixtoken.json';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BSC_TESTNET_RPC = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
const BSC_MAINNET_RPC = 'https://bsc-dataseed.binance.org/';

let currentChainId = '0x38'; // Default to BSC. You can adjust this as needed.

async function getCurrentChainId() {
    try {
        currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
    } catch (err) {
        console.error("Failed to get current chain:", err);
    }
}

getCurrentChainId();

const getRPCURL = (chainId) => {
    return chainId === '0x38' ? BSC_MAINNET_RPC : BSC_TESTNET_RPC;
};

function getRefFromURL() {
    const params = new URLSearchParams(window.location.search);
    return params.get('ref') || "";
}

const web3 = new Web3(window.ethereum ? new Web3(window.ethereum) : new Web3(new Web3.providers.HttpProvider(getRPCURL(currentChainId))));

// Web3 FUNCTION
export default function Register() {
    const [state] = useContext(Context);
    const [isLoading, setLoading] = useState(false);
    const [ref, setRef] = useState(getRefFromURL());

    useEffect(() => {
        switchNetwork();

        const refFromURL = getRefFromURL();
        if (refFromURL) {
            setRef(refFromURL);
        }
    }, []);

    async function switchNetwork(desiredChainId = '0x38') {
        const networkData = {
            '0x61': {
                rpcUrl: BSC_TESTNET_RPC,
                chainName: 'BSC Testnet',
                nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
                blockExplorerUrls: ['https://testnet.bscscan.com/'],
            },
            '0x38': {
                rpcUrl: BSC_MAINNET_RPC,
                chainName: 'Binance Smart Chain',
                nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
                blockExplorerUrls: ['https://bscscan.com/'],
            },
        };

        try {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (currentChainId !== desiredChainId) {
                const networkInfo = networkData[desiredChainId];
                if (!networkInfo) throw new Error('Desired chain ID not supported.');

                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: desiredChainId }],
                    });
                } catch (switchError) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: desiredChainId,
                                    rpcUrls: [networkInfo.rpcUrl],
                                    chainName: networkInfo.chainName,
                                    nativeCurrency: networkInfo.nativeCurrency,
                                    blockExplorerUrls: networkInfo.blockExplorerUrls,
                                }],
                            });
                        } catch (addError) {
                            console.error(`Failed to add ${networkInfo.chainName} to MetaMask:`, addError);
                        }
                    } else {
                        console.error(`Failed to switch to ${networkInfo.chainName}:`, switchError);
                    }
                }
            }
        } catch (err) {
            console.error("Failed to get or switch chains:", err);
        }
    }

    async function checkNetwork() {
        try {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (currentChainId === '0x38' || currentChainId === '0x61') {
                return true;
            }
            return false;
        } catch (err) {
            console.error("Failed to get chain or check network:", err);
            return false;
        }
    }

    // start register submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValidNetwork = await checkNetwork();
        if (!isValidNetwork) {
            alert("Please switch to the BSC before proceeding.");
            return;
        }

        const address = state.account || "";
        // need data for fill call contract input
        const endpoint = `https://api.matrix.win/api/matrix/${address}?level=1&ref=${ref}`;

        try {
            const response = await axios.get(endpoint);
            const data = response.data;

            if (data && data.status === "success") {
                await callSmartContract(data.data);
            }
        } catch (error) {
            console.error("Error fetching data from the API:", error);
            toast.error("Ref code not found");
        }
    };
    // end register submit

    // start approve tokens
    async function approveTokens(tokenAddress, contractAddress, tokenAmount) {
        try {
            const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
            const accounts = await web3.eth.getAccounts();

            return tokenContract.methods.approve(contractAddress, tokenAmount)
                .send({ from: accounts[0] })
                .once('confirmation', (confirmationNumber, receipt) => {
                    if (receipt.status) {
                        console.log("Token Approval Confirmed:", receipt);
                        toast.success("Token Approval Confirmed");
                        return receipt;
                    } else {
                        console.error("Token approval failed:", receipt);
                        toast.error("Token Approval failed");
                        throw new Error("Token approval failed");
                    }
                })
                .on('error', (error) => {
                    console.error("Transaction Error:", error);
                    if (error.message && error.message.includes("User denied transaction signature")) {
                        toast.error("You rejected the transaction in MetaMask!");
                    } else {
                        toast.error("Transaction failed. Please try again.");
                    }
                });
        } catch (error) {
            console.error("Error approving tokens:", error);
            throw error;
        }
    }
    // end approve tokens

    // start call smart contract
    async function callSmartContract(data) {
        try {
            const contract = new web3.eth.Contract(contractABI, data.contractAddress);
            const accounts = await web3.eth.getAccounts();

            const payableAmountInEther = web3.utils.fromWei(data.gasAmount, 'ether');
            const payableAmount = web3.utils.toWei(payableAmountInEther, 'ether');
            const tokenAmountBN = web3.utils.toBN(data.tokenAmount);

            console.log(`Sending a transaction with msg.value of: ${payableAmountInEther} ether`);

            setLoading(true);
            // before call contract, need approve token from token address to matrix protocol contract
            const receipt = await approveTokens(data.tokenAddress, data.contractAddress, data.tokenAmount);

            if (receipt && receipt.status) {
                const result = await contract.methods.sendToMatrix(
                    data.backendWallet,
                    tokenAmountBN,
                    data.tokenAddress,
                    1
                ).send({ from: accounts[0], value: payableAmount })
                    .once('confirmation', (confirmationNumber, receipt) => {
                        if (receipt.status) {
                            console.log("Registration Confirmed:", receipt);
                            toast.success("Registration Confirmed");
                            return receipt;
                        } else {
                            console.error("Registration failed:", receipt);
                            toast.error("Registration failed");
                            throw new Error("Registration failed");
                        }
                    })
                    .on('error', (error) => {
                        console.error("Transaction Error:", error);
                        if (error.message && error.message.includes("User denied transaction signature")) {
                            toast.error("You rejected the transaction in MetaMask!");
                        } else {
                            toast.error("Transaction failed. Please try again.");
                        }
                    });

                // debuging the result from call contract
                // console.log(result);

                // POST to the provided API after successful transaction
                const txhash = result.transactionHash.toString();
                const refString = ref.toString();

                if (txhash) {
                    const postBody = {
                        wallet: accounts[0],
                        level: "1",
                        txhash: txhash,
                        ref: refString
                    };
                    const postResponse = await axios.post('https://api.matrix.win/api/matrix', postBody, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    console.log("API Post Response:", postResponse.data);

                    // start redirect if success
                    if (postResponse.data && postResponse.data.status === "success") {
                        // after success will be redirect to dashboard pages
                        window.location.href = "/dashboard";
                    } else {
                        console.error("API POST request failed:", postResponse.data);
                        toast.error("Failed to register. Please try again.");
                        // alert("Failed to register. Please try again.");
                    }
                    // end redirect
                }
            } else {
                console.error("Token approval was not successful. Aborting transaction.");
            }
        } catch (error) {
            console.error("Error calling the smart contract:", error);
        } finally {
            setLoading(false);
            // window.location.href = "/dashboard";
        }
    }
    // end call smart contract

    return (
        <div>
            <Header />
            {/* loading component */}
            {isLoading && <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
                <div className="text-white">Loading until approve and register done (2 popups)...</div>
            </div>}
            {/* end loading component */}
            <div className="w-full px-4 py-20 bg-gray-800 md:px-20 lg:px-28">
                <center>
                    <img src="/images/logo_b.png" width="400px" height="120px" alt="" />
                </center>

                <div className='justify-center p-6 mt-12 text-center text-white bg-gray-900 rounded-lg'>
                    <h1 className='py-4 text-2xl font-semibold text-yellow-500'>Welcome</h1>
                    <p className='py-3'>To keep connected with us please login with your Address.</p>

                    <form onSubmit={handleSubmit} className='flex justify-center gap-2 mt-5'>
                        <input
                            type="text"
                            name="ref"
                            placeholder="Enter Referral ID"
                            className='p-3 text-white bg-gray-800 rounded-lg'
                            value={ref}
                            onChange={e => setRef(e.target.value)}
                        />
                        <button type="submit" name="submit" className='p-3 text-black bg-yellow-500 rounded-lg'>Registration</button>
                    </form>

                    <div className='flex flex-wrap justify-center gap-1 my-8'>
                        <div className='font-semibold text-white'>Already Have an Account? </div>
                        <div className='font-semibold text-yellow-500'>Just connect with registered wallet and go to dashboard on top menu</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}