import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Footer, Header } from '../components/bnbmatrix-ui';
import { Context } from '../Store';
import 'react-toastify/dist/ReactToastify.css';

// Web3 FUNCTION
export default function Transaction() {
    const [state] = useContext(Context);
    const [isLoading, setLoading] = useState(false);
    const [log, setLog] = useState([]);

    function formatDate(apiDateString) {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // parse string tanggal dari API ke objek Date
        const date = new Date(apiDateString);

        // konversi bagian tanggal ke format yang diinginkan
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');

        // gabungkan bagian tanggal ke dalam string format yang diinginkan
        return `${month}, ${day} ${year}, ${hour}:${minute}:${second}`;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (state.web3 && state.account) {
            const fetchData = async () => {
                try {
                    const response = await axios.get('https://api.matrix.win/api/log/' + state.account);
                    //   console.log(response.data);
                    // Accessing the refCode from the nested data object
                    setLog(response.data.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchData();
        }
    }, [state]);

    return (
        <div>
            <Header />
            {/* end loading component */}
            <div className="w-full px-4 py-20 bg-gray-800 md:px-20 lg:px-28">
                <center>
                    <img src="/images/logo_b.png" width="400px" height="120px" alt="" />
                </center>

                <div className='justify-center p-6 mt-12 text-center text-white bg-gray-900 rounded-lg'>
                    <h1 className='py-4 text-2xl font-semibold text-yellow-500'>Your Transaction</h1>

                    <div className='w-full overflow-x-scroll'>
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead className="bg-gray-50 dark:bg-gray-800">
                                <tr>
                                    <th scope="col" className="py-3.5 px-4 text-sm font-normal text-center rtl:text-right text-gray-500 dark:text-gray-400">
                                        <div className="flex items-center gap-x-3">
                                            <button className="flex items-center gap-x-2">
                                                <span>ID</span>
                                            </button>
                                        </div>
                                    </th>

                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Date
                                    </th>

                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Status
                                    </th>

                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-800 divide-y divide-gray-900 dark:divide-gray-700 dark:bg-gray-900">
                                {log.map((logTx, txIndex) => {
                                    return (
                                        <tr key={txIndex}>
                                            <td className="px-4 py-4 text-sm font-medium text-left text-gray-200 dark:text-gray-200 whitespace-nowrap">
                                                <div className="inline-flex items-start text-left">
                                                    <span>#{logTx._id}</span>
                                                </div>
                                            </td>
                                            <td className="px-4 py-4 text-sm text-left text-gray-200 dark:text-gray-300 whitespace-nowrap">{formatDate(logTx.lastUpdate)}</td>
                                            <td className="px-4 py-4 text-sm font-medium text-left text-gray-200 whitespace-nowrap">
                                                <div className="inline-flex items-start px-2 py-1 text-left rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 dark:bg-gray-800">
                                                    <h2 className="text-sm font-normal capitalize">{logTx.status}</h2>
                                                </div>
                                            </td>
                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                <div className="flex items-center gap-x-6">
                                                    <a href={'https://bscscan.com/tx/' + logTx.txhash} className="text-gray-500 transition-colors duration-200 dark:hover:text-indigo-500 dark:text-gray-300 hover:text-indigo-500 focus:outline-none">
                                                        Check Log
                                                    </a>

                                                    <a href={'https://bscscan.com/tx/' + logTx.result} className="text-blue-500 transition-colors duration-200 hover:text-indigo-500 focus:outline-none">
                                                        Check Distribution
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}