import BigNumber from "bignumber.js"

export const parseFromWei = (amount, decimal = 18, precision = 8) => {
  let amountBigNumber = new BigNumber(amount)
  const divison = new BigNumber(10 ** decimal);
  return amountBigNumber.dividedBy(divison).toFixed(precision)
}

export const isGreaterThan = (one, two) => {
  const _one = new BigNumber(one);
  const _two = new BigNumber(two);
  return _two.isGreaterThan(_one);
}

export function formatNumber(angka) {
  const parts = angka.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(".");
}