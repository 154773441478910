import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./Store";
import Dashboard from "./pages/Dashboard";
import Ranking from "./pages/Ranking";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Transaction from "./pages/Transaction";

export default function Navigation() {
    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/dashboard"} element={<Dashboard />} />
                    <Route path={"/rank"} element={<Ranking />} />
                    <Route path={"/register"} element={<Register />} />
                    <Route path={"/transaction"} element={<Transaction />} />
                </Routes>
            </BrowserRouter>
        </Store>
    )
}