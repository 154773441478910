import React, { useContext, useEffect, useState } from 'react'
import { Footer, Header } from '../components/bnbmatrix-ui'
import { RiGroupLine, RiRefreshLine, RiShoppingCartLine } from 'react-icons/ri'
import axios from 'axios';
import { Context } from '../Store';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import contractABI from '../abi/matrixprotocol.json';
import tokenABI from '../abi/matrixtoken.json';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber } from '../libs/utils';

const BSC_TESTNET_RPC = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
const BSC_MAINNET_RPC = 'https://bsc-dataseed.binance.org/';

let currentChainId = '0x38'; // Default to BSC. You can adjust this as needed.

async function getCurrentChainId() {
    try {
        currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
    } catch (err) {
        console.error("Failed to get current chain:", err);
    }
}

getCurrentChainId();

const getRPCURL = (chainId) => {
    return chainId === '0x38' ? BSC_MAINNET_RPC : BSC_TESTNET_RPC;
};

const web3 = new Web3(window.ethereum ? new Web3(window.ethereum) : new Web3(new Web3.providers.HttpProvider(getRPCURL(currentChainId))));

function formatNumberFromAPI(apiOutput) {
    const earning = new BigNumber(apiOutput);
    const result = earning.dividedBy(new BigNumber("1000000000000000000"));

    return result.toNumber();
}

export default function Dashboard() {
    const [refCode, setRefCode] = useState(null);
    const [refRegisterCode, setRefRegisterCode] = useState(null);
    const [allParticipant, setAllParticipant] = useState(0);
    const [dailyParticipant, setDailyParticipant] = useState(0);
    const [earningParticipant, setEarningParticipant] = useState(0);
    const [earningMe, setEarningMe] = useState({});
    const [descendant, setDescendant] = useState([]);
    const [matrixContract, setMatrixContract] = useState("0x");
    const [level, setLevel] = useState(0);
    const [registered, setRegistered] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [state] = useContext(Context);
    const [matrixPriceData, setMatrixPriceData] = useState({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (state.web3 && state.account) {
            const fetchData = async () => {
                try {
                    const response = await axios.get('https://api.matrix.win/api/user/' + state.account);
                    //   console.log(response.data);
                    // Accessing the refCode from the nested data object
                    setRegistered(response.data.status);
                    setRefCode(response.data.data.refCode);
                    setRefRegisterCode(response.data.data.refUsed);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            fetchData();

            // start fetching earning per user (based on wallet)
            const fetchEarningMe = async () => {
                try {
                    const response = await axios.get('https://api.matrix.win/api/user/' + state.account);
                    setEarningMe(response.data.data);
                    setLevel(response.data.data.highestLevel);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchEarningMe();
            // end fetching earning per user (based on wallet)

            // start fetching descendant (matrix tree - based on wallet address)
            const fetchDescendant = async () => {
                try {
                    const response = await axios.get('https://api.matrix.win/api/descendants/' + state.account);
                    setDescendant(response.data.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchDescendant();
            // end fetching descendant

            // start fetching contract address
            const fetchContractAddress = async () => {
                try {
                    let hexChainId = await window.ethereum.request({ method: 'eth_chainId' });
                    const currentChainId = parseInt(hexChainId, 16); // convert hex to integer
                    const response = await axios.get('https://api.matrix.win/api/contractaddress?chainid=' + currentChainId);
                    setMatrixContract(response.data.data.CONTRACT_ADDRESS);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchContractAddress();
            // end fetching contract address


        }
    }, [state]);

    useEffect(() => {
        // start all participant (count)
        const fetchAllParticipant = async () => {
            try {
                const response = await axios.get('https://api.matrix.win/api/allparticipant');
                setAllParticipant(response.data.data.userAmount);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchAllParticipant();
        // end all participant

        // start all daily participant (count)
        const fetchDailyParticipant = async () => {
            try {
                const response = await axios.get('https://api.matrix.win/api/dailyparticipant');
                setDailyParticipant(response.data.data.userAmount);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchDailyParticipant();
        // end all daily participant

        // start all earning participant (count)
        const fetchEarningParticipant = async () => {
            try {
                const response = await axios.get('https://api.matrix.win/api/earningparticipant');
                setEarningParticipant(response.data.data.earningParticipant);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchEarningParticipant();
        // end all earning participant
    }, [])

    // start get matrix price for update level, etc (render price on component, exp: 50000)
    useEffect(() => {
        axios.get('https://api.matrix.win/api/matrixprice')
            .then(response => {
                setMatrixPriceData(response.data.data.matrixPriceList);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);
    // end get matrix price

    // start render for squre on matrix tree (descendant)
    const renderDiv = (width, height, count, totalRemaining) => {
        let toBeColored = Math.min(totalRemaining, count);
        let newTotalRemaining = totalRemaining - toBeColored;

        return {
            divs: (
                <>
                    {Array.from({ length: count }).map((_, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`flex items-center justify-center w-${width} h-${height} p-1 ${idx < toBeColored ? 'bg-yellow-500' : 'bg-white'
                                    } rounded-md`}
                            ></div>
                        );
                    })}
                </>
            ),
            remaining: newTotalRemaining
        };
    };
    // end render for square

    // use effect for check current chain id
    useEffect(() => {
        switchNetwork();
    }, []);
    // end check current chain id

    async function switchNetwork(desiredChainId = '0x38') {
        const networkData = {
            '0x61': {
                rpcUrl: BSC_TESTNET_RPC,
                chainName: 'BSC Testnet',
                nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
                blockExplorerUrls: ['https://testnet.bscscan.com/'],
            },
            '0x38': {
                rpcUrl: BSC_MAINNET_RPC,
                chainName: 'Binance Smart Chain',
                nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
                blockExplorerUrls: ['https://bscscan.com/'],
            },
        };

        try {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (currentChainId !== desiredChainId) {
                const networkInfo = networkData[desiredChainId];
                if (!networkInfo) throw new Error('Desired chain ID not supported.');

                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: desiredChainId }],
                    });
                } catch (switchError) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: desiredChainId,
                                    rpcUrls: [networkInfo.rpcUrl],
                                    chainName: networkInfo.chainName,
                                    nativeCurrency: networkInfo.nativeCurrency,
                                    blockExplorerUrls: networkInfo.blockExplorerUrls,
                                }],
                            });
                        } catch (addError) {
                            console.error(`Failed to add ${networkInfo.chainName} to MetaMask:`, addError);
                        }
                    } else {
                        console.error(`Failed to switch to ${networkInfo.chainName}:`, switchError);
                    }
                }
            }
        } catch (err) {
            console.error("Failed to get or switch chains:", err);
        }
    }

    async function checkNetwork() {
        try {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (currentChainId === '0x38' || currentChainId === '0x61') {
                return true;
            }
            return false;
        } catch (err) {
            console.error("Failed to get chain or check network:", err);
            return false;
        }
    }

    // start update level
    // after user click submit, the function will call function with name callSmartContract (need approve)
    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValidNetwork = await checkNetwork();
        if (!isValidNetwork) {
            alert("Please switch to the BSC before proceeding.");
            return;
        }

        const address = state.account || "";
        // need to get all data for call contract
        const endpoint = `https://api.matrix.win/api/matrix/${address}?level=${level + 1}&ref=${refRegisterCode}`;

        try {
            const response = await axios.get(endpoint);
            const data = response.data;

            if (data && data.status === "success") {
                await callSmartContract(data.data);
            }
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };

    async function approveTokens(tokenAddress, contractAddress, tokenAmount) {
        try {
            const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
            const accounts = await web3.eth.getAccounts();

            return tokenContract.methods.approve(contractAddress, tokenAmount)
                .send({ from: accounts[0] })
                .once('confirmation', (confirmationNumber, receipt) => {
                    if (receipt.status) {
                        console.log("Token Approval Confirmed:", receipt);
                        toast.success("Token Approval Confirmed");
                        return receipt;
                    } else {
                        console.error("Token approval failed:", receipt);
                        toast.error("Token Approval failed");
                        throw new Error("Token approval failed");
                    }
                })
                .on('error', (error) => {
                    console.error("Transaction Error:", error);
                    if (error.message && error.message.includes("User denied transaction signature")) {
                        toast.error("You rejected the transaction in MetaMask!");
                    } else {
                        toast.error("Transaction failed. Please try again.");
                    }
                });
        } catch (error) {
            console.error("Error approving tokens:", error);
            throw error;
        }
    }

    async function callSmartContract(data) {
        try {
            const contract = new web3.eth.Contract(contractABI, data.contractAddress);
            const accounts = await web3.eth.getAccounts();

            const payableAmountInEther = web3.utils.fromWei(data.gasAmount, 'ether');
            const payableAmount = web3.utils.toWei(payableAmountInEther, 'ether');
            const tokenAmountBN = web3.utils.toBN(data.tokenAmount);

            console.log(`Sending a transaction with msg.value of: ${payableAmountInEther} ether`);

            setLoading(true);
            const receipt = await approveTokens(data.tokenAddress, data.contractAddress, data.tokenAmount);

            if (receipt && receipt.status) {
                const result = await contract.methods.sendToMatrix(
                    data.backendWallet,
                    tokenAmountBN,
                    data.tokenAddress,
                    level + 1
                ).send({ from: accounts[0], value: payableAmount })
                    .once('confirmation', (confirmationNumber, receipt) => {
                        if (receipt.status) {
                            console.log("Update Level Confirmed:", receipt);
                            toast.success("Update Level Confirmed");
                            return receipt;
                        } else {
                            console.error("Update Level failed:", receipt);
                            toast.error("Update Level failed");
                            throw new Error("Update Level failed");
                        }
                    })
                    .on('error', (error) => {
                        console.error("Transaction Error:", error);
                        if (error.message && error.message.includes("User denied transaction signature")) {
                            toast.error("You rejected the transaction in MetaMask!");
                        } else {
                            toast.error("Transaction failed. Please try again.");
                        }
                    });

                console.log(result);

                // POST to the provided API after successful transaction
                const txhash = result.transactionHash.toString();
                const refString = refRegisterCode.toString();

                if (txhash) {
                    const postBody = {
                        wallet: accounts[0],
                        level: level + 1,
                        txhash: txhash,
                        ref: refString
                    };
                    const postResponse = await axios.post('https://api.matrix.win/api/matrix', postBody, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    console.log("API Post Response:", postResponse.data);

                    if (postResponse.data && postResponse.data.status === "success") {
                        toast.success("Update Level Success, please refresh the pages");
                    } else {
                        toast.error("Update Level failed, please try again");
                        console.error("API POST request failed:", postResponse.data);
                    }
                }
            } else {
                console.error("Token approval was not successful. Aborting transaction.");
            }
        } catch (error) {
            console.error("Error calling the smart contract:", error);
        } finally {
            setLoading(false);
            // window.location.href = "/dashboard";
        }
    }
    // end update level

    // start reinvest
    const handleSubmitReinvest = async (e, lev) => {
        e.preventDefault();

        const isValidNetwork = await checkNetwork();
        if (!isValidNetwork) {
            alert("Please switch to the BSC before proceeding.");
            return;
        }

        const address = state.account || "";
        const endpoint = `https://api.matrix.win/api/matrix/${address}?level=${lev}&ref=${refRegisterCode}`;

        try {
            const response = await axios.get(endpoint);
            const data = response.data;

            if (data && data.status === "success") {
                await callSmartContractReinvest(data.data, lev);
            }
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };

    async function approveTokensReinvest(tokenAddress, contractAddress, tokenAmount) {
        try {
            const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
            const accounts = await web3.eth.getAccounts();

            return tokenContract.methods.approve(contractAddress, tokenAmount)
                .send({ from: accounts[0] })
                .once('confirmation', (confirmationNumber, receipt) => {
                    if (receipt.status) {
                        console.log("Token Approval Confirmed (Reinvest):", receipt);
                        toast.success("Token Approval Confirmed (Reinvest)");
                        return receipt;
                    } else {
                        console.error("Token approval failed (Reinvest):", receipt);
                        toast.error("Token approval failed (Reinvest)");
                        throw new Error("Token approval failed");
                    }
                })
                .on('error', (error) => {
                    console.error("Transaction Error:", error);
                    if (error.message && error.message.includes("User denied transaction signature")) {
                        toast.error("You rejected the transaction for approval reinvest in MetaMask!");
                    } else {
                        toast.error("Transaction reinvest failed. Please try again.");
                    }
                });
        } catch (error) {
            console.error("Error approving tokens:", error);
            throw error;
        }
    }

    async function callSmartContractReinvest(data, lev) {
        try {
            const contract = new web3.eth.Contract(contractABI, data.contractAddress);
            const accounts = await web3.eth.getAccounts();

            const payableAmountInEther = web3.utils.fromWei(data.gasAmount, 'ether');
            const payableAmount = web3.utils.toWei(payableAmountInEther, 'ether');
            const tokenAmountBN = web3.utils.toBN(data.tokenAmount);

            console.log(`Sending a transaction with msg.value of: ${payableAmountInEther} ether and level ${lev}`);

            setLoading(true);
            const receipt = await approveTokensReinvest(data.tokenAddress, data.contractAddress, data.tokenAmount);

            if (receipt && receipt.status) {
                const result = await contract.methods.sendToMatrix(
                    data.backendWallet,
                    tokenAmountBN,
                    data.tokenAddress,
                    lev
                ).send({ from: accounts[0], value: payableAmount })
                    .once('confirmation', (confirmationNumber, receipt) => {
                        if (receipt.status) {
                            console.log("Re-invest Confirmed:", receipt);
                            toast.success("Re-invest Confirmed");
                            return receipt;
                        } else {
                            console.error("Re-invest failed:", receipt);
                            toast.error("Re-invest failed");
                            throw new Error("Re-invest failed");
                        }
                    })
                    .on('error', (error) => {
                        console.error("Transaction Error:", error);
                        if (error.message && error.message.includes("User denied transaction signature")) {
                            toast.error("You rejected the transaction for re-invest in MetaMask!");
                        } else {
                            toast.error("Transaction re-invest failed. Please try again.");
                        }
                    });

                // for debugging result from smart contract call
                // console.log(result);

                // POST to the provided API after successful transaction
                const txhash = result.transactionHash.toString();
                const refString = refRegisterCode.toString();

                if (txhash) {
                    const postBody = {
                        wallet: accounts[0],
                        level: lev,
                        txhash: txhash,
                        ref: refString
                    };
                    const postResponse = await axios.post('https://api.matrix.win/api/matrix', postBody, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    console.log("API Post Response:", postResponse.data);

                    if (postResponse.data && postResponse.data.status === "success") {
                        toast.success("Re-invest Success, please refresh the pages");
                    } else {
                        toast.error("Re-invest failed, please try again");
                        console.error("API POST request failed:", postResponse.data);
                    }
                }
            } else {
                console.error("Token approval was not successful. Aborting transaction.");
            }
        } catch (error) {
            console.error("Error calling the smart contract:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Header />
            {isLoading && <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
                <div className="text-white">Loading...</div>
            </div>}
            {registered === "success" ? (
                <div className="w-full px-4 py-20 bg-gray-800 md:px-20 lg:px-4">
                    <center>
                        <img src="/images/logo_b.png" width="400px" height="120px" alt="" />
                    </center>

                    <div className="flex flex-col gap-3 mt-8 md:flex-row lg:flex-row xl:flex-row">
                        <div className="w-full md:w-3/12 lg:w-3/12 xl:w-3/12">
                            <div className="flex flex-col w-full gap-4">
                                <div className="text-white border border-gray-900 rounded-lg">
                                    <div className="p-6 bg-gray-900">
                                        <h1 className="mb-3 font-semibold text-yellow-500">User Information</h1>
                                        <div className="flex justify-between w-full">
                                            <div className='flex flex-col'>
                                                <div>User Ref: </div>
                                                <div>Highest level: </div>
                                                <div>Reward: </div>
                                            </div>
                                            <div className='flex flex-col text-right'>
                                                <div>{earningMe.refCode}</div>
                                                <div>{earningMe.highestLevel}</div>
                                                <div>{formatNumber(formatNumberFromAPI(earningMe.totalRewardReceived))} Matrix</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-6 py-2 text-center text-gray-900 bg-yellow-500">
                                        {formatNumber(formatNumberFromAPI(earningMe.totalRewardReceived))} Matrix
                                    </div>
                                </div>
                                <div className="p-6 text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className="mb-3 font-semibold text-yellow-500">Total Income</h1>
                                    <div className='flex flex-col gap-4'>
                                        <div>{formatNumber(formatNumberFromAPI(earningMe.totalRewardReceived))} Matrix</div>
                                        <div className="text-green-400">↑ (0.0000) Matrix</div>
                                    </div>
                                </div>
                                <div className="p-6 text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className="mb-3 font-semibold text-yellow-500">Affiliate link</h1>
                                    <div className='flex flex-col gap-4'>
                                        <div>
                                            <input type="text" className='w-full px-2 py-1 text-white bg-gray-800 border border-yellow-500 rounded-md' placeholder='0x' defaultValue={refCode && window.location.origin + '/register?ref=' + refCode} />
                                        </div>
                                        <div className='flex'>
                                            <div className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>Copy</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6 text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className="mb-3 font-semibold text-yellow-500">Your wallet</h1>
                                    <div className='flex flex-col gap-4'>
                                        <div>
                                            <input type="text" className='w-full px-2 py-1 text-white bg-gray-800 border border-yellow-500 rounded-md' placeholder='0x' defaultValue={state.account} />
                                        </div>
                                        <div className='flex justify-between'>
                                            {currentChainId === '0x61' ? (
                                                <a href={'https://testnet.bscscan.com/address/' + state.account} className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>BSCSCAN</a>
                                            ) : (
                                                <a href={'https://bscscan.com/address/' + state.account} className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>BSCSCAN</a>
                                            )}
                                            <div className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>Copy</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6 text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className="mb-3 font-semibold text-yellow-500">Smart Contract Address</h1>
                                    <div className='flex flex-col gap-4'>
                                        <div>
                                            <input type="text" className='w-full px-2 py-1 text-white bg-gray-800 border border-yellow-500 rounded-md' placeholder='0x' defaultValue={matrixContract && matrixContract} />
                                        </div>
                                        <div className='flex justify-between'>
                                            {currentChainId === '0x61' ? (
                                                <a href={'https://testnet.bscscan.com/address/' + matrixContract} className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>BSCSCAN</a>
                                            ) : (
                                                <a href={'https://bscscan.com/address/' + matrixContract} className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>BSCSCAN</a>
                                            )}
                                            <div className='px-3 py-2 text-gray-900 bg-yellow-500 rounded-md'>Copy</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-9/12 lg:w-9/12 xl:w-9/12">
                            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                                <div className="w-full p-6 text-center text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className='text-lg font-semibold text-yellow-500'>{allParticipant}</h1>
                                    <div className="text-sm text-white">All Participant</div>
                                </div>
                                <div className="w-full p-6 text-center text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className='text-lg font-semibold text-green-500'>↑ +{dailyParticipant}</h1>
                                    <div className="text-sm text-white">Joined in 24 hours</div>
                                </div>
                                <div className="w-full p-6 text-center text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className='text-lg font-semibold text-yellow-500'>{formatNumber(formatNumberFromAPI(earningParticipant))} Matrix</h1>
                                    <div className="text-sm text-white">Participants have earned Matrix</div>
                                </div>
                                <div className="w-full p-6 text-center text-white bg-gray-900 border border-gray-900 rounded-lg">
                                    <h1 className='text-lg font-semibold text-yellow-500'>5,220.00 USD</h1>
                                    <div className="text-sm text-white">Participants have earned usd</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-3 mt-8 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                                {descendant.map((descendantItem, index) => {
                                    let totalToColor = descendantItem.descendants.length;
                                    const levelKey = "LEVEL_" + descendantItem.level;
                                    const priceForLevel = matrixPriceData[levelKey] || "N/A";

                                    return (
                                        <div key={index} className={level === descendantItem.level ? 'mb-3 border rounded-lg border-yellow-500' : 'mb-3 border rounded-lg border-gray-900'}>
                                            <div className='relative w-full text-center text-white bg-gray-900 rounded-lg'>
                                                <div className="absolute flex items-center justify-center w-8 h-8 p-1 ml-4 -mt-4 bg-gray-900 border border-yellow-600 rounded-md">
                                                    {descendantItem.level}
                                                </div>
                                                <div className='w-full p-6 text-gray-900 bg-yellow-500 rounded-t-md'>
                                                    {formatNumber(priceForLevel)} Matrix
                                                </div>
                                            </div>

                                            <div className='w-full p-6 text-white bg-gray-900 rounded-b-md'>
                                                <div className="flex flex-col gap-6">
                                                    {[
                                                        { width: 8, height: 8, count: 2, gap: "gap-48" },
                                                        { width: 8, height: 8, count: 4, gap: "gap-20" },
                                                        { width: 6, height: 6, count: 8, gap: "gap-9" },
                                                        { width: 5, height: 5, count: 16, gap: "gap-3" },
                                                        { width: 1, height: 1, count: 32, gap: "gap-2" }
                                                    ].map(config => {
                                                        const renderResult = renderDiv(config.width, config.height, config.count, totalToColor);
                                                        totalToColor = renderResult.remaining;
                                                        return (
                                                            <div key={config.count} className={`flex justify-center w-full ${config.gap}`}>
                                                                {renderResult.divs}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>

                                            <div className='w-full p-6 text-white bg-gray-900 rounded-b-md'>
                                                <div className="flex justify-between w-full">
                                                    <div className='flex items-center gap-1 text-yellow-500'>
                                                        <RiGroupLine /> <div className='text-white'>{descendantItem.descendants.length}</div>
                                                    </div>
                                                    <div className='flex items-center gap-1 text-yellow-500'>
                                                        {descendantItem.descendants.length === 62 ? (
                                                            <form onSubmit={(e) => handleSubmitReinvest(e, descendantItem.level)} className='flex justify-center gap-2'>
                                                                <button type='submit' className='flex items-center gap-1 px-3 py-2 text-yellow-500 bg-gray-900 border border-yellow-500 rounded-md'>
                                                                    <RiRefreshLine /> Re-invest
                                                                </button>
                                                            </form>
                                                        ) : (
                                                            <div className='flex items-center gap-1 px-3 py-2 text-yellow-500 bg-gray-900 border border-yellow-500 rounded-md'>Need {62 - descendantItem.descendants.length} to reinvest</div>
                                                        )}
                                                    </div>
                                                    <div className='flex items-center gap-1 text-yellow-500'>
                                                        <RiRefreshLine /> <div className='text-white'>{descendantItem.reinvestCount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {/* State Component For Activate */}
                                {level + 1 !== 5 ? (
                                    <div className="relative w-full h-full text-center text-white bg-gray-900 border border-gray-900 rounded-lg">
                                        <div className="absolute flex items-center justify-center w-8 h-8 p-1 ml-4 -mt-4 bg-gray-900 border border-yellow-600 rounded-md">
                                            {level + 1}
                                        </div>
                                        <div className='w-full p-6 text-gray-900 bg-yellow-500 rounded-t-md'>
                                            {formatNumber(matrixPriceData["LEVEL_" + (level + 1)]) || "N/A"} Matrix
                                        </div>
                                        <div className='w-full p-6 text-yellow-500 bg-gray-900 h-max rounded-b-md'>
                                            <form onSubmit={handleSubmit} className='flex justify-center gap-2 mt-5'>
                                                <button type='submit' className="flex flex-col items-center justify-center w-full h-full gap-1 my-auto">
                                                    <RiShoppingCartLine /> Activate
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full px-4 py-20 bg-gray-800 md:px-20 lg:px-28">
                    <div className="w-full p-6 bg-gray-900 rounded-lg">
                        <span className="text-2xl font-semibold text-yellow-500">
                            Please Register First, For access all menu
                        </span>
                    </div>
                </div>
            )}
            <Footer />
        </div >
    )
}
