import React from "react";
import { Footer, Header } from "../components/bnbmatrix-ui";

export default function Home() {
    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-900 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug text-white dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                                Welcome to <span className="text-yellow-500">Matrix Win</span>
                            </div>
                            <div className="my-8 text-lg text-white">
                                Matrix.Money is an innovative crypto matrix that allows participants to earn on multilevel marketing with the help of a smart contract. Ensuring security and transparency, the project offers powerful opportunities for growing your income and developing your business
                            </div>
                            <a href="https://" className="px-4 py-3 mt-4 text-black bg-yellow-500 rounded-md">
                                Whitepaper
                            </a>
                            <a href="/register" className="px-4 py-3 mt-4 ml-3 text-black bg-yellow-500 rounded-md">
                                Register
                            </a>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img
                                    src="./images/matrix-illustration.png"
                                    className="w-9/12"
                                    alt=""
                                />
                            </div>
                        </div>
                    </section>
                </section>


                <div className="w-full px-4 py-20 bg-gray-900 md:px-20 lg:px-28">
                    <h1 className="flex items-center gap-2 mb-3 text-3xl font-semibold text-white">About <div className="text-yellow-500">Matrix Win</div></h1>
                    <h2 className="mb-8 text-lg text-white">More explanation about Matrix Win</h2>
                    <div className="container grid gap-8 lg:grid-cols-2 lg:grid-rows-2">
                        <div className="flex flex-col row-span-2 bg-gray-900 border border-yellow-500 rounded-md">
                            <div className="flex-1 h-1/2"
                            ><img src="/images/bnbmatrix-bg-1.png" className="object-cover object-right-top w-full" alt="omnichannel" /></div>
                            <div className="p-10">
                                <h3 className="text-xl font-medium text-gray-300">Matrix Win Explain</h3>
                                <p className="mt-2 text-white">Matrix.Money is an innovative crypto matrix that allows participants to earn on multilevel marketing with the help of a smart contract!</p>
                                <a href="" className="inline-flex mt-2 text-yellow-500">Read More →</a>
                            </div>
                        </div>
                        <div className="flex bg-gray-900 border border-yellow-500 rounded-md">
                            <div className="flex-1 p-10">
                                <h3 className="text-xl font-medium text-gray-300">100% Decentralized</h3>
                                <p className="mt-2 text-white">Matrix Win is a peer to peer matrix platform. Matrix Win is a peer to peer matrix platform. All payments go directly to the members! You will NEVER have a company mismanage your funds!.</p>
                                <a href="" className="inline-flex mt-2 text-yellow-500">Read More →</a>
                            </div>

                            <div className="relative hidden w-1/3 h-full overflow-hidden lg:block">
                                <div className="absolute inset-0">
                                    <img src="/images/bnbmatrix-bg-3.png" className="object-cover object-left-top w-full h-full" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="flex bg-gray-900 border border-yellow-500 rounded-md">
                            <div className="flex-1 p-10">
                                <h3 className="text-xl font-medium text-gray-300">Easy to Manage</h3>
                                <p className="mt-2 text-white">Matrix Win smart contract set 2 tiers of referral rewards. BNB Matrix smart contract set 2 tiers of referral rewards. The referral rewards are distributed to your balance automatically and you can withdraw at anytime.</p>
                                <a href="" className="inline-flex mt-2 text-yellow-500">Read More →</a>
                            </div>

                            <div className="relative hidden w-1/3 h-full overflow-hidden lg:block">
                                <div className="absolute inset-0">
                                    <img src="/images/bnbmatrix-bg-2.png" className="object-cover object-left-top w-full h-full" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 py-12 bg-gray-900 md:px-20 lg:px-28">
                    <div className="flex flex-col-reverse items-center justify-between w-full gap-8 md:flex-row lg:flex-row">
                        <div className="w-full md:w-8/12 lg:w-8/12">
                            <h1 className="flex items-center gap-2 mb-10 text-3xl font-semibold text-white">Our <div className="text-yellow-500">Mission</div></h1>
                            <div className="w-full text-xl text-white">
                                To remove mental and physical challenges from the minds of the individual by giving loving environment
                                to them so as to make them free from fear and self-pity, along with an education with training which shall
                                create competencies in personality for their respective fields and inculcate the feeling of self-appreciation.
                            </div>
                        </div>
                        <div className="w-full md:w-4/12 lg:w-4/12">
                            <img src="/images/mission-bg.png" className="w-full" alt="" />
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 py-12 bg-gray-900 md:px-20 lg:px-28">
                    <div className="flex flex-col items-center justify-between w-full gap-8 md:flex-row lg:flex-row">
                        <div className="w-full lg:w-5/12 md:w-5/12">
                            <img src="/images/matrix-bg.png" className="w-full" alt="" />
                        </div>
                        <div className="w-full md:w-7/12 lg:w-7/12">
                            <h1 className="flex items-center gap-2 mb-10 text-3xl font-semibold text-white">Matrix <div className="text-yellow-500">Level</div></h1>
                            <div className="w-full text-xl text-white">
                                To participate in our matrix, a person must purchase our token. Our token can be bought on a decentralized exchange, and there are no commissions involved with our token. Commissions only apply when participating in our matrix.
                                A person can register using a link provided by another individual. Each person can invite an unlimited number of people via their link. All individuals who register using that link will purchase their first level specifically from the person who invited them.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 py-12 bg-gray-900 md:px-20 lg:px-28">
                    <div className="flex flex-col items-center justify-between w-full gap-8 md:flex-row lg:flex-row">
                        <div className="w-full md:w-7/12 lg:w-7/12">
                            <h1 className="flex items-center gap-2 mb-10 text-3xl font-semibold text-white">Matrix <div className="text-yellow-500">Commision</div></h1>
                            <div className="w-full text-xl text-white">
                                When a person purchases a new level, there is always a 30% commission. Among this commission: <br />
                                - <b className="text-yellow-500">5%</b> is added to the project's liquidity<br />
                                - <b className="text-yellow-500">5%</b> is distributed among all token holders, regardless of whether they participate in the matrix or not; these rewards occur in the native token of the project<br />
                                - <b className="text-yellow-500">20%</b> goes to marketing<br /><br />
                                These 20% should be automatically converted to USDT and sent to the marketing wallet in USDT. The remaining 70% is distributed as follows:<br />
                                - <b className="text-yellow-500">30%</b> goes to the person from whom you are supposed to buy your <b className="text-yellow-500">first level</b><br />
                                - <b className="text-yellow-500">20%</b> goes to the person from whom you are supposed to buy the <b className="text-yellow-500">second level</b><br />
                                - <b className="text-yellow-500">10%</b> goes to the person from whom you are supposed to buy the <b className="text-yellow-500">third level</b><br />
                                - <b className="text-yellow-500">5%</b> goes to the person from whom you are supposed to buy the <b className="text-yellow-500">fourth level</b><br />
                                - <b className="text-yellow-500">5%</b> goes to the person from whom you are supposed to buy the <b className="text-yellow-500">fifth level</b>
                            </div>
                        </div>
                        <div className="w-full md:w-5/12 lg:w-5/12">
                            <img src="/images/matrix-level-bg.png" className="w-full" alt="" />
                        </div>
                    </div>
                </div>

                <section className="w-full px-4 py-12 bg-gray-900 md:px-20 lg:px-28">
                    <div className="text-white ">
                        <div className="container flex flex-col items-start mx-auto my-12 md:flex-row md:my-24">
                            <div className="sticky flex flex-col w-full mt-2 lg:w-1/3 md:top-36 md:mt-12">
                                <p className="text-yellow-500 uppercase tracking-loose">Roadmap</p>
                                <p className="mb-2 text-3xl leading-normal md:text-4xl md:leading-relaxed">Our Roadmap</p>
                                <p className="mb-4 text-sm md:text-base text-gray-50">
                                    Here’s your guide to see all roadmap of Matrix Win
                                </p>
                            </div>
                            <div className="sticky ml-0 md:ml-12 lg:w-2/3">
                                <div className="container w-full h-full mx-auto">
                                    <div className="relative h-full p-4 overflow-hidden md:p-10 lg:p-10 wrap">
                                        <div className="absolute h-full border border-2-2 border-yellow-555"
                                            style={{ right: '50%', border: '2px solid #FFC100', borderRadius: '1%' }}></div>
                                        <div className="absolute h-full border border-2-2 border-yellow-555"
                                            style={{ left: '50%', border: '2px solid #FFC100', borderRadius: '1%' }}></div>
                                        <div className="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline">
                                            <div className="order-1 w-5/12"></div>
                                            <div className="order-1 w-5/12 px-1 py-4 text-right">
                                                <p className="mb-3 text-base text-yellow-500">First Phase</p>
                                                <h4 className="mb-3 text-lg font-bold md:text-2xl">Stage 1</h4>
                                                <div className="text-sm leading-snug text-opacity-100 md:text-base text-gray-50">
                                                    1. Development of an innovative crypto matrix concept based on smart contracts. <br />
                                                    2. Creation of Matrix.Win platform prototype and internal token.<br />
                                                    3. Translating the platform into multiple languages.<br />
                                                    4. Testing smart contracts and token on the blockchain test network.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between w-full mb-8 right-timeline">
                                            <div className="order-1 w-5/12"></div>
                                            <div className="order-1 w-5/12 px-1 py-4 text-left">
                                                <p className="mb-3 text-base text-yellow-500">Second Phase</p>
                                                <h4 className="mb-3 text-lg font-bold md:text-2xl">Stage 2</h4>
                                                <div className="text-sm leading-snug text-opacity-100 md:text-base text-gray-50">
                                                    5. Development of partnership program and marketing strategies.<br />
                                                    6. Engaging influencers for project promotion.<br />
                                                    7. Launch of the project token and announcement of fairlaunch.<br />
                                                    8. Strengthening marketing efforts and additional influencer engagement.<br />
                                                    9. Listing the project on decentralized exchanges and preparing for listing on centralized exchanges.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row-reverse items-center justify-between w-full mb-8 left-timeline">
                                            <div className="order-1 w-5/12"></div>
                                            <div className="order-1 w-5/12 px-1 py-4 text-right">
                                                <p className="mb-3 text-base text-yellow-500"> Third Phase</p>
                                                <h4 className="mb-3 text-lg font-bold md:text-2xl">Stage 3</h4>
                                                <div className="text-sm leading-snug text-opacity-100 md:text-base text-gray-50">
                                                    10. Listing on CoinGecko and CoinMarketCap.<br />
                                                    11. Optimization and finalization of the matrix platform and smart contracts.<br />
                                                    12. Launch of the Matrix.Win platform and user registration.<br />
                                                    13. Development and implementation of a mobile application for convenient account management and quick access to the matrix.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between w-full mb-8 right-timeline">
                                            <div className="order-1 w-5/12"></div>

                                            <div className="order-1 w-5/12 px-1 py-4">
                                                <p className="mb-3 text-base text-yellow-500">Fourth Phase</p>
                                                <h4 className="mb-3 text-lg font-bold text-left md:text-2xl">Stage 4</h4>
                                                <p className="text-sm leading-snug text-opacity-100 md:text-base text-gray-50">
                                                    14. Conducting webinars and training courses for participants. 15. Listing the project on centralized exchanges.<br />
                                                    16. Expanding the global presence of Matrix.Win through the creation of local technical and marketing teams.<br />
                                                    17. Implementing additional earning tools and opportunities on
                                                    the platform, adding related products and services for participants.<br />
                                                    18. Maintaining active marketing and collaboration with
                                                    influencers throughout the project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <img className="mx-auto -mt-36 md:-mt-10" src="/images/roadmap-bg.png" alt="roadmap" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="w-full px-4 py-12 bg-gray-900 md:px-20 lg:px-28">
                    <div className="flex justify-between w-full">
                        <a href="https://pinksale.finance">
                            <img src="/images/partner/pinksale.png" style={{ height: '50px' }} alt="" />
                        </a>
                        <a href="https://blocksafu.com">
                            <img src="/images/partner/blocksafu.png" style={{ height: '50px' }} alt="" />
                        </a>
                    </div>
                </section>

                <div className="w-full px-4 py-12 bg-gray-900 md:px-20 lg:px-28">
                    <div className="w-full p-8 bg-yellow-500 rounded-xl">
                        <div className="flex flex-col items-center justify-between w-full md:flex-row lg:flex-row">
                            <div>
                                <div className="text-sm">BNB WALLET MUST REQUIRE <br />
                                    FOR ENROLLMENT & ONLY
                                </div>
                                <h1 className="my-5 text-5xl font-bold text-center text-gray-900 md:text-left lg:text-left">Only 50,000 Matrix TO START</h1>
                                <h2 className="text-3xl font-bold text-center text-gray-900 md:text-left lg:text-left">Direct Referal 50%</h2>
                            </div>
                            <button className="px-6 py-3 mt-6 text-lg font-semibold text-center bg-white rounded-lg md:mt-0 lg:mt-0">Let's Start Now</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}