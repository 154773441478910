import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Footer, Header } from '../components/bnbmatrix-ui'

export default function Ranking() {
    // let [rankingDeposit, setRankingDeposit] = useState('')
    // let [rankingProfit, setRankingProfit] = useState('')
    // let [rankingCommision, setRankingCommision] = useState('')

    // useEffect(() => {
    //     getRankingDeposit();
    //     getRankingProfit();
    //     getRankingCommision();
    // }, []);

    // const getRankingDeposit = () => {
    //     axios.get('isidenganurldomainbe')
    //         .then(res => {
    //             setRankingDeposit(res.data)
    //         })
    // }

    // const getRankingProfit = () => {
    //     axios.get('isidenganurldomainbe')
    //         .then(res => {
    //             setRankingProfit(res.data)
    //         })
    // }

    // const getRankingCommision = () => {
    //     axios.get('isidenganurldomainbe')
    //         .then(res => {
    //             setRankingCommision(res.data)
    //         })
    // }

    return (
        <div>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative px-4 py-4 mx-auto overflow-hidden bg-gray-800 dark:bg-gray-900 md:py-20 lg:py-20 font-body lg:px-28">
                    <h1 className='mb-2 text-3xl font-semibold text-yellow-500'>All Stats Ranking</h1>
                    <div className='mb-5 text-lg text-white'>All information about ranking</div>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-1 lg:grid-cols-1">
                        <div className="py-3">
                            <h1 className='mb-2 text-lg font-semibold tracking-wide text-white'>Ranking Deposit</h1>
                            <table class="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Wallet
                                        </th>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Level
                                        </th>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">
                                                0x
                                            </p>
                                        </td>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">
                                                Level 1
                                            </p>
                                        </td>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">$1</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="py-3">
                            <h1 className='mb-2 text-lg font-semibold tracking-wide text-white'>Ranking Profit</h1>

                            <table class="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Wallet
                                        </th>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Level
                                        </th>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">
                                                0x
                                            </p>
                                        </td>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">
                                                Level 1
                                            </p>
                                        </td>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">$1</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="py-3">
                            <h1 className='mb-2 text-lg font-semibold tracking-wide text-white'>Ranking Commision</h1>

                            <table class="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Wallet
                                        </th>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Level
                                        </th>
                                        <th
                                            class="px-5 py-3 border-b-2 border-gray-900 bg-yellow-500 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">
                                                0x
                                            </p>
                                        </td>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">
                                                Level 1
                                            </p>
                                        </td>
                                        <td class="px-5 py-3 border-b border-gray-900 bg-gray-900 text-sm">
                                            <p class="text-gray-200 whitespace-no-wrap">$1</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div >
    )
}
